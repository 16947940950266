import { css } from '@superset-ui/core';
import {
  CircleArrowLeft,
  Database,
  PanelsLeftBottom,
  Search,
  Plus,
} from 'lucide-react';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { Container } from './commons/Container';
import { Flex } from './commons/Flex';
import SectionGroup from './commons/SectionGroup';
import usePerm from './hooks/usePerm';
import useUser from './hooks/useUser';
import MenuItem from './MenuItem';
import { useStore } from './store';
import { MenuType } from './types';

function CreateTab() {
  const { setIsCreateSectionOpen, sidebarWidth } = useStore();
  const {
    canDashboard,
    canDataset,
    showUploads,
    canUploadCSV,
    canUploadColumnar,
    canUploadExcel,
    nonExamplesDBConnected,
    isAdmin,
    allowUploads,
    canSql,
  } = usePerm();
  const { isUserAdmin } = useUser();

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (e.target === null) return;

      const isALink = (e.target as HTMLElement).className.includes('linker');

      if (isALink) {
        setIsCreateSectionOpen(false);
      }
    };

    document.addEventListener('click', onClick);

    return () => document.removeEventListener('click', onClick);
  }, [setIsCreateSectionOpen]);

  const datasetsActions = [
    {
      name: 'Create dataset',
      url: '/dataset/add',
      hasAccess: canDataset && nonExamplesDBConnected,
    },
    {
      name: 'Upload CSV to database',
      url: '/csvtodatabaseview/form',
      hasAccess: canUploadCSV && showUploads,
      disabled: isAdmin && !allowUploads,
    },
    {
      name: 'Upload Columnar to database',
      url: '/columnartodatabaseview/form',
      hasAccess: canUploadColumnar && showUploads,
      disabled: isAdmin && !allowUploads,
    },
    {
      name: 'Upload Excel file to database',
      url: '/exceltodatabaseview/form',
      hasAccess: canUploadExcel && showUploads,
      disabled: isAdmin && !allowUploads,
    },
  ];

  const hasDatasetsActions = datasetsActions.some(({ hasAccess }) => hasAccess);

  const dashboardActions = [
    {
      name: 'Create new Dashboard',
      url: '/dashboard/new',
      hasAccess: canDashboard,
    },
    // { name: 'Import Dashboard', url: '', hasAccess: true },
  ];

  const projectActions = [
    {
      name: 'Create new Project',
      url: '/superset/project/create/',
      hasAccess: isUserAdmin,
    },
  ];

  const hasDashboardsActions = canDashboard;

  const isDesktop = useMediaQuery('(min-width : 768px)');

  return (
    <Container width={isDesktop ? sidebarWidth : '100%'} zIndex={60}>
      <Flex direction="row" align="center" gap="8px">
        <button
          onClick={() => setIsCreateSectionOpen(false)}
          css={css`
            background: none;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
          type="button"
        >
          <CircleArrowLeft />
        </button>
        <h6
          css={css`
            font-size: 1rem;
          `}
        >
          Create new Component
        </h6>
      </Flex>
      <div
        css={css`
          margin-left: 8px;
        `}
      >
        {hasDatasetsActions && (
          <SectionGroup
            type="CREATE"
            name="Datasets"
            icon={<Database />}
            actions={datasetsActions}
          />
        )}
        {hasDashboardsActions && (
          <SectionGroup
            type="CREATE"
            name="Dashboards"
            icon={<PanelsLeftBottom />}
            actions={dashboardActions}
          />
        )}
        {isUserAdmin && (
          <SectionGroup
            type="CREATE"
            name="Projects"
            icon={<Plus />}
            actions={projectActions}
          />
        )}
      </div>
      {canSql && (
        <MenuItem
          className="linker"
          isActive={false}
          name="SQL Query"
          icon={<Search />}
          type={MenuType.Button}
          path="/sqllab?new=true"
        />
      )}
    </Container>
  );
}

export default CreateTab;
