import React, { useMemo } from 'react';
import {
  BarChart,
  Database,
  Home,
  PanelsLeftBottom,
  Terminal,
} from 'lucide-react';
import { useLocation } from 'react-router-dom';
import { Menu, MenuType } from './types';
import MenuItem from './MenuItem';
import CollapseButton from './CollapseButton';
import useUser from './hooks/useUser';
import { useStore } from './store';

type MenuAndAccess = Menu & { hasAccess: boolean };

function MenuList() {
  const location = useLocation();

  const { isAuthed, isBytearkAdmin } = useUser();

  const { isTextHidden } = useStore();

  const menus: MenuAndAccess[] = useMemo(
    () => [
      {
        name: 'Home',
        icon: <Home size="1.5em" />,
        type: MenuType.Button,
        path: '/superset/welcome',
        hasAccess: true,
      },

      {
        name: 'Dashboards',
        icon: <PanelsLeftBottom size="1.5em" />,
        type: MenuType.Button,
        path: '/dashboard/list',
        hasAccess: true,
      },
      {
        name: 'Charts',
        icon: <BarChart size="1.5em" />,
        type: MenuType.Button,
        path: '/chart/list',
        hasAccess: true,
      },
      {
        name: 'Datasets',
        icon: <Database size="1.5em" />,
        type: MenuType.Button,
        path: '/tablemodelview/list',
        hasAccess: isBytearkAdmin,
      },
      {
        name: 'SQL',
        icon: <Terminal size="1.5em" />,
        type: MenuType.Collapse,
        path: '/dashboard/list',
        hasAccess: isBytearkAdmin,
      },
    ],
    [isAuthed, isBytearkAdmin],
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        flex: isTextHidden ? '1 1 auto' : undefined,
      }}
    >
      {menus
        .filter(({ hasAccess }) => hasAccess)
        .map(menu => {
          if (menu.type === MenuType.Button) {
            return (
              <MenuItem
                isActive={location.pathname.startsWith(menu.path)}
                key={menu.name}
                {...menu}
              />
            );
          }
          return <CollapseButton key={menu.name} {...menu} />;
        })}
    </div>
  );
}

export default MenuList;
